import React, { FunctionComponent } from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"
import * as S from "../components/pages/404/404.style"

interface ErrorProps {
  location: Location
}

const NotFoundPage: FunctionComponent<ErrorProps> = ({ location }) => (
  <Layout bigHeader={false}>
    <SEO location={location} title={`Página não encontrada`} />
    <S.Error>
      <S.ErrorTitle>404</S.ErrorTitle>
      <S.ErrorDescription>Página não encontrada</S.ErrorDescription>
      <S.BackLink to={`/`}>← Página Inicial</S.BackLink>
    </S.Error>
  </Layout>
)

export default NotFoundPage
